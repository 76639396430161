import React from "react";
import { graphql } from "gatsby";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const Careers = loadable(() => import("@components/Careers/Careers"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));

const CareersPage = ({ path, data }) => (
    <>
        <SEO
            title="Join Our Team and Build Your Careers with Us!"
            titleTemplate="Impressit"
            description="Want to become a part of an exceptional team? And we are always ready to hire and to add some great professionals to the Impressit team. Send us your CV!"
            path={path}
        />
        <Layout path={path}>
            <Careers data={data} />
        </Layout>
    </>
);

export default CareersPage;

export const query = graphql`
    query MyQuery {
        allRestApiApiVacancies {
            edges {
                node {
                    id
                }
            }
        }
        restApiApiVacancies {
            result {
                data {
                    working_conditions
                    work_types
                    updated_at
                    status_id
                    responsibility
                    residence
                    published
                    open_date
                    on_site_id
                    number_of_positions
                    name
                    id
                    hot_flag
                    experience
                    description
                    demand
                    created_at
                    company_name
                    close_date
                    addition
                }
            }
        }
    }
`;
